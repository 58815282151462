import React, { useState, useEffect, Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "react-slick"
import { withPrefix } from "gatsby-link"
import "../components/container.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Jedilnik = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allWpJedilnik(
        filter: { acfJedilnik: { jedilnik: { kategorija: { ne: "Malice" } } } }
      ) {
        edges {
          node {
            acfJedilnik {
              jedilnik {
                cena
                cenaDnevniJedilnik
                cenamalapica
                cenavelikapica
                imeJedi
                kategorija
                opisJedi
              }
            }
          }
        }
      }
    }
  `)
  const {
    allWpJedilnik: { edges },
  } = data

  const [filterArray, setFilterArray] = useState(null)
  const [jedilnik, setJedilnik] = useState(null)
  const [jedilnikLeft, setJedilnikLeft] = useState(null)
  const [jedilnikRight, setJedilnikRight] = useState(null)
  const [bg, setBg] = useState(false)
  const [clicked, setClicked] = useState({ name: "Pice" })
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 1000,

    slidesToShow: 5,
    slidesToScroll: 1,
    useCSS: true,
    useTransform: true,
    autoplaySpeed: 5000,
    autoplay: false,
    pauseOnHover: true,
    lazyLoad: true,
    dotsclassName: "slider-dots",
    className: "slider-jedilnik",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  })

  const changeBg = () => {
    setBg(true)
  }

  useEffect(() => {
    if (edges) {
      let newArray = []
      let newNewArray = []
      let uniqueItems = []
      console.log("edges", edges)
      edges.forEach(item => {
        newArray.push(item)
        newArray.map(item => {
          newNewArray.push(item.node.acfJedilnik.jedilnik.kategorija)
        })
        uniqueItems = Array.from(new Set(newNewArray))
      })
      var half_length = Math.ceil(newArray.length / 2)
      var leftSide = newArray.slice(0, half_length)
      var rightSide = newArray.slice(half_length, newArray.length)
      setJedilnik(l => newArray)
      setJedilnikLeft(l => leftSide)
      setJedilnikRight(l => rightSide)
      setFilterArray(l => uniqueItems)
    }
  }, [])

  const clickHandle = name => {
    setClicked({ name: name })
  }
  console.log("array", filterArray)
  return (
    <Layout>
      <SEO
        title="Jedilnik"
        description="Pri nas dobite dobrote izpod rok vrhunskih kuharjev in picopekov."
      />

      <section
        className="menus menu food-menu section-padding"
        style={{ backgroundImage: "url(/images/food-menu-bg-image-2.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 mb-40 text-center">
              <h6 className="sub-title">Picerija jaka</h6>
              <h4 className="title">Jedilnik</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="row">
                <div className="tabs-icon mb-40 col-lg-10 offset-lg-1 text-center">
                  <Slider {...settings}>
                    {!filterArray ? (
                      <p>Loading</p>
                    ) : (
                      filterArray.map((item, i) => {
                        return (
                          <div
                            id={
                              item
                                ? "tab-" + [item.split(" ").join("")]
                                : "tab-" + "pice"
                            }
                            key={
                              item
                                ? "tab-" + [item.split(" ").join("")]
                                : "tab-" + "pice"
                            }
                            className="item"
                            onClick={() => clickHandle(item)}
                          >
                            {" "}
                            <span
                              className={
                                "icon food-icon food-icon-" +
                                [item.split(" ").join("")]
                              }
                            ></span>
                            <h6>{item}</h6>
                          </div>
                        )
                      })
                    )}
                  </Slider>
                </div>
                <div className="menus-content col-md-12 ">
                  <h5 className="text-center">{clicked.name}</h5>{" "}
                  {clicked.name === "Pice" && (
                    <div className="menus-content__subtitles">
                      <p>Mala</p> <p>Velika</p>{" "}
                    </div>
                  )}
                  {!jedilnik ? (
                    <p>Loading</p>
                  ) : (
                    jedilnik.map((item, index) => {
                      return (
                        <div
                          id={
                            clicked.name == "pice"
                              ? "tab-" + "pice"
                              : "tab-" +
                                [item.node.acfJedilnik.jedilnik.kategorija] +
                                "-content"
                          }
                          key={
                            "tab-" +
                            [item.node.acfJedilnik.jedilnik.kategorija] +
                            "-content" +
                            index
                          }
                          className={
                            item.node.acfJedilnik.jedilnik.kategorija ===
                            clicked.name
                              ? "jedilnik-display cont"
                              : "cont"
                          }
                          style={{
                            display:
                              item.node.acfJedilnik.jedilnik.kategorija ===
                              clicked.name
                                ? "block"
                                : "none",
                          }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div className={ 'menu-info menu-info--' + item.node.acfJedilnik.jedilnik.kategorija }>
                                <h5>
                                  {item.node.acfJedilnik.jedilnik.imeJedi}{" "}
                                  {clicked.name === "Pice" ? (
                                    <Fragment>
                                      <div>
                                        <span
                                          className="price"
                                          style={{ marginLeft: "1rem" }}
                                        >
                                          {
                                            item.node.acfJedilnik.jedilnik
                                              .cenavelikapica
                                          }{" "}
                                          eur
                                        </span>
                                        <span className="price">
                                          {
                                            item.node.acfJedilnik.jedilnik
                                              .cenamalapica
                                          }{" "}
                                          eur
                                        </span>
                                      </div>
                                    </Fragment>
                                  ) : (
                                    <span className="price">
                                      {item.node.acfJedilnik.jedilnik.cena}
                                      {" "} eur
                                    </span>
                                  )}
                                </h5>
                                <p className={
                                  item.node.acfJedilnik.jedilnik.opisJedi
                                    ? ""
                                    : "empty-description"
                                }>
                                  {item.node.acfJedilnik.jedilnik.opisJedi} &nbsp;
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Jedilnik
